.button-type-one {
    background: transparent;
    position: relative;
    transition: all 0.4s;
    overflow: hidden;
    color: black;
    border-radius: 9px;
    border: 1px solid black;
    font-size: 15px;
    padding: 0.5vh 2.5vh;
    font-weight: 300;
  }
  
  .button-type-one::before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: black;
    transition: all 0.4s;
  }
  
  .button-type-one:hover::before {
    width: 100%;
  }
  
  .button-type-one:hover {
    color: white; 
  }
  
  .button-type-two {
    color: black;
    font-weight: 500;
    position: relative;
    border: 1px solid var(--card-type-one-color);
    background-color: var(--card-type-one-color);
    width: max-content;
    overflow: hidden;
    padding: 0.5vh 2.5vh;
    cursor: pointer;
    border-radius: 9px;
    transition: background-position 0.5s ease !important;
    background-size: 200% 100%;
    background-image: linear-gradient(
      to right,
      var(--card-type-one-color) 50%,
      black 50%
    );
    -webkit-transition: background-position 0.5s ease !important;
    -moz-transition: background-position 0.5s ease !important;
    -ms-transition: background-position 0.5s ease !important;
    -o-transition: background-position 0.5s ease !important;
  }
  .button-type-two:hover {
    background-position: -100% 0;
color: white;
    background-color: var(--color-three);
  
  }
  .home-section-one-main img{
    width: 70%;
  }


  .button-type-three {
    background: transparent;
    position: relative;
    transition: all 0.4s;
    overflow: hidden;
    color: white;
    border-radius: 9px;
    border: 1px solid white;
    font-size: 15px;
    padding: 0.5vh 2.5vh;
    font-weight: 300;
  }
  
  .button-type-three::before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: white;
    transition: all 0.4s;
  }
  
  .button-type-three:hover::before {
    width: 100%;
  }
  
  .button-type-three:hover {
    color: black; 
  }
  .button-type-four {
    color: black;
    position: relative;
    border: 1px solid var(--card-type-one-color);
    background-color: white;
    width: max-content;
    overflow: hidden;
    padding: 0.5vh 2.5vh;
    border-radius: 9px;
    transition: background-position 0.5s ease;
    background-size: 200% 100%;
    background-image: linear-gradient(to right,white 50%, var(--color-three) 50%);
  }
  .button-type-four:hover {
    background-position: -100% 0;

    background-color: var(--color-three);
  
  }
  .button-type-five {
    background: transparent;
    position: relative;
    transition: all 0.4s;
    overflow: hidden;
    color: white;
    border-radius: 9px;
    border: 2px solid white;
    font-size: 15px;
    padding: 0.5vh 2.5vh;
    font-weight: 300;
  }
  
  .button-type-five::before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: white;
    transition: all 0.4s;
  }
  
  .button-type-five:hover::before {
    width: 100%;
  }
  
  .button-type-five:hover {
    color: black 
  }

  .button-type-six {
    background: transparent;
    position: relative;
    transition: all 0.4s;
    overflow: hidden;
    color: black;
    border-radius: 9px;
    border: 2px solid black;
    font-size: 15px;
    padding: 0.5vh 2.5vh;
    font-weight: 300;
  }
  
  .button-type-six::before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: black;
    transition: all 0.4s;
  }
  
  .button-type-six:hover::before {
    width: 100%;
  }
  
  .button-type-six:hover {
    color: white; 
  }

  
  
 .sorted-select-hover:hover{
  color:var(--span-main-color)

 }