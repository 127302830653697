.home-slider-main {
  position: relative;
  height: 100%;
}
.home-slider-main .swiper {
  width: 100%;
  height: 100%;
  margin-top: 105px;
}
.home-slider-main .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slide-by-text {
  text-shadow: 0 5px 10px #0004;
}
.home-slider-main .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home-slider-main .swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  color: white !important;
}
.home-slider-main .swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  color: white !important;
}
.home-slider-main .swiper-pagination-bullet-active {
  background: white !important;
}
.home-slider-main .slide-container {
  position: relative;
  display: inline-block;
  width: 1000%;
  height: 80vh;
}

.home-slider-main .slide-text {
  position: absolute;
  top: 50%;
  left: 15%;
  transform: translate(-15%, -50%);
  color: white;
  font-size: 20px;
}
.slide-text h2 {
  line-height: 35px;
  letter-spacing: 1px;
}
.slide-text h3 {
  line-height: 35px;
  letter-spacing: 1px;
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
  .home-slider-main .swiper {
    height: 70vh;
    margin-top: 17vh;
  }
  .home-slider-main .slide-container {
    height: 60vh;
  }
  .home-slider-main .slide-text {
    left: 30%;
    transform: translate(-30%, -50%);
    font-size: 16px;
  }
}

/* Small mobile adjustments */
@media screen and (max-width: 430px) {
  .home-slider-main .swiper {
    height: 75vh;
    margin-top: 20vh;
  }
  .home-slider-main .slide-text {
    font-size: 18px; /* Slightly larger text */
    left: 25%;
    transform: translate(-25%, -50%);
  }
}

/* Specific adjustments for screens 430px width and 932px height */
@media screen and (width: 430px) and (height: 932px) {
  .home-slider-main .swiper {
    height: 80vh;
    margin-top: 10vh;
  }
  .home-slider-main .slide-container {
    height: 70vh;
  }
  .home-slider-main .slide-text {
    font-size: 20px; /* Increased font size for readability */
    left: 28%; /* Center text a bit more */
    top: 75%;
    transform: translate(-28%, -50%);
  }
  .home-slider-main .swiper-slide img {
    object-position: 50% 0 
  }
}

@media screen and (min-width: 769px) and (max-width: 100px) {
  .home-slider-main .swiper {
    height: 85vh;
    margin-top: 7vh;
  }
}

@media screen and (min-width: 800px) {
  .slide-main-text {
    font-size: 3rem;
  }
}

.custom-slide-adjust .slide-text {
  top: 80%; 
}

.custom-slide-adjust-2 .slide-text {
  top: 75%; 
  left: 9.5%;
}

.custom-slide-adjust-3 .slide-text {
  top: 70%; 
  left: 10%;
}

.custom-slide-adjust-4 .slide-text {
  top: 68%; 
  left: 10%;
}

.custom-slide-adjust-5 .slide-text {
  top: 70%; 
  left: 10.5%;
}

.custom-slide-adjust .button-type-five {
  margin-top: 30px; 
}



.custom-slide-adjust img {
  margin-top: 20px; /* Adjusts the logo position */
}

.custom-slide-adjust .button-type-five {
  margin-top: 30px; /* Adjusts button position */
}

